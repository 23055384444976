import React, { memo, useEffect, useRef, useState } from "react";

// components
import Player from "./components/Player";

// styles
import GlobalStyle from "./global-styles";
import deepEqual from "./utils/deep-equal";

// types
declare global {
  interface Window {
    STRIMM_PLAYER: {
      [key: string]: {
        url: string;
        startDate: string;
        showPlaceHolderImage: boolean;
        placeHolderImage: string;
      };
    };
  }
}

interface PlayerData {
  url: string | null;
  startDate: string | null;
  showPlaceHolderImage: boolean;
  placeHolderImage: string | null;
}

const defaultLocalData: PlayerData = {
  url: null,
  startDate: null,
  showPlaceHolderImage: false,
  placeHolderImage: null,
};

// entry
function App() {
  const [localData, setLocalData] = useState<PlayerData>(defaultLocalData);
  const playerRef = useRef<HTMLDivElement>(null);

  // on mount
  useEffect(() => {
    const intervalCounter = setInterval(() => {
      const playerRootId = playerRef?.current?.closest(
        ".STRIMM_PLAYER_ROOT"
      )?.id;

      if (
        "STRIMM_PLAYER" in window &&
        playerRootId &&
        window.STRIMM_PLAYER[playerRootId] &&
        "url" in window.STRIMM_PLAYER[playerRootId] &&
        "startDate" in window.STRIMM_PLAYER[playerRootId] &&
        !deepEqual(localData, window.STRIMM_PLAYER[playerRootId])
      ) {
        setLocalData((prevState) => ({
          ...prevState,
          ...window.STRIMM_PLAYER[playerRootId],
        }));
      }
    }, 1000);

    return () => {
      clearInterval(intervalCounter);
    };
  }, [localData]);

  return (
    <div ref={playerRef}>
      <Player
        source={localData.url}
        startDate={localData.startDate}
        showPlaceHolderImage={localData.showPlaceHolderImage}
        placeHolderImage={localData.placeHolderImage}
      />

      <GlobalStyle />
    </div>
  );
}

export default memo(App);
