import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .STRIMM_PLAYER_ROOT {
    font-family: Roboto-Regular, 'Helvetica Neue', Helvetica, Arial, sans-serif;
	  -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    
    & *, & *::before, & *::after {
      box-sizing: border-box;
    }
    
    input, select {
      border: none;
    }
}
`;

export default GlobalStyle;
