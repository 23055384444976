import {VolumeBarItemType} from "./types";

const initVolumeBar: Array<VolumeBarItemType> = [
  {
    id: 0,
    isActive: false,
  },
  {
    id: 1,
    isActive: false,
  },
  {
    id: 2,
    isActive: false,
  },
  {
    id: 3,
    isActive: false,
  },
  {
    id: 4,
    isActive: false,
  },
  {
    id: 5,
    isActive: false,
  },
  {
    id: 6,
    isActive: false,
  },
];

export { initVolumeBar };
