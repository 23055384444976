/**
 *
 * UI
 *
 */

import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./styles";

function Unmute({ onClick, text }) {
  return (
    <Wrapper type="button" onClick={onClick}>
      <span>{text}</span>
    </Wrapper>
  );
}

Unmute.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default Unmute;
