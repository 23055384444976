export const MATCH_URL_YOUTUBE =
  /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([^&]+)/;
export const MATCH_URL_SHORT_YOUTUBE =
  /^(https?:\/\/)?(www\.)?youtu\.be\/([^?]+)/;
export const MATCH_URL_YOUTUBE_EMBED =
  /^(https?:\/\/)?(www\.)?youtube\.com\/embed\/([^?]+)/;

export default function isYouTubeLink(url: string) {
  return (
    MATCH_URL_YOUTUBE.test(url) ||
    MATCH_URL_SHORT_YOUTUBE.test(url) ||
    MATCH_URL_YOUTUBE_EMBED.test(url)
  );
}
