import React from "react";

import { Wrapper, ImagePlaceholder } from "./styles";

type Props = {
  showPlaceHolderImage: boolean;
  placeHolderImage: string;
};

function AudioPlaceholder({ showPlaceHolderImage, placeHolderImage }: Props) {
  return (
    <Wrapper>
      {showPlaceHolderImage && placeHolderImage && placeHolderImage.length ? (
        <ImagePlaceholder src={placeHolderImage} alt="placeholder" />
      ) : (
        <></>
      )}
    </Wrapper>
  );
}

export default AudioPlaceholder;
