import styled from 'styled-components';

const Wrapper = styled.button`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  height: 37px;
  line-height: 37px;
  min-width: 170px;
  padding: 0 10px;
  background-color: #fff;
  border-radius: 2px;
  border: none;
  outline: none;
  cursor: pointer;

  & > span {
    display: inline-block;
    font-size: 14px;
    line-height: 37px;
    vertical-align: top;
  }

  &::before {
    display: inline-block;
    vertical-align: top;
    margin-right: 7px;
    font-family: flowplayer;
    font-size: 2em;
    line-height: 37px;
    content: '\\e00d';
  }
`;

export { Wrapper };
