import styled from 'styled-components';

interface WrapperProps {
  readonly screenFullActive: boolean;
  readonly fullscreenFallbackEnabled: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  background-color: black;
  padding-bottom: ${`${(9 / 16) * 100}%`};

  ${(props) => props.screenFullActive ? `
    position: static;
    padding-bottom: 0;
  ` : null}

  ${(props) =>
    props.fullscreenFallbackEnabled
      ? `
    position: fixed;
    padding-bottom: 0;
    top: 0%;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 13;
    background-color: black;
  `
      : null}

  & > .react-player {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }
`;


export { Wrapper };
